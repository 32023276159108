import classNames from "classnames";
import PropTypes from "prop-types";
import "./Location.scss";

function Location({flag, children, label = '', url = '', blank = false, comingSoon = false}) {
    const redirect = () => {
        if (!comingSoon && url !== '') {
            if (blank) {
                window.open(url);
            } else {
                window.location.href = url;
            }
        }
    };

    return (
        <div className={classNames("Location", {'-coming-soon': comingSoon})} onClick={redirect}>
            <img src={flag} className="_flag" alt={children}/>

            <span>{children || label} {comingSoon && <><br/>(coming soon)</>}</span>
        </div>
    );
}

Location.propTypes = {
    children: PropTypes.string
};

export default Location;