import uk from "svg-country-flags/svg/gb.svg";
import at from "svg-country-flags/svg/at.svg";
import fr from "svg-country-flags/svg/fr.svg";
import de from "svg-country-flags/svg/de.svg";
import it from "svg-country-flags/svg/it.svg";
import es from "svg-country-flags/svg/es.svg";
import ch from "svg-country-flags/svg/ch.svg";
// import eu from "svg-country-flags/svg/eu.svg";

export const Locations = [
    {
        label: 'Austria',
        flag: at,
        url: 'https://www.lytgobi.de/',
        blank: true,
    },
    {
        label: 'France',
        flag: fr,
        url: 'https://www.lytgobi.fr/',
        blank: true,
    },
    {
        label: 'Germany',
        flag: de,
        url: 'https://www.lytgobi.de/',
        blank: true
    },
    {
        label: 'Italy',
        flag: it,
        url: 'https://lytgobi.it/',
        comingSoon: true,
    },
    {
        label: 'Spain',
        flag: es,
        url: 'https://www.lytgobi.es/',
        blank: true,
        // comingSoon: true,
    },
    {
        label: 'United Kingdom',
        flag: uk,
        url: 'https://lytgobi.uk/',
        comingSoon: true,
    },
    // {
    //     label: 'EU (English)',
    //     flag: eu,
    //     url: 'http://lytgobi.eu/',
    //     comingSoon: true,
    // },
    {
        label: 'Switzerland',
        flag: ch,
        url: 'https://lytgobi.ch/',
        comingSoon: true,
    },
];
